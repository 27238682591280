import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const VolunteerPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-4.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Volunteer</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Builds</h2>
					<h5 className="mb-0">New Construction</h5>
					<p>Our mission is to build homes for
					economically challenged families. We
					love volunteers to help! While
					construction skills are always helpful,
					anyone who has a passion for
					construction and helping families will
					be welcome.</p>
					<h5 className="mb-0">Repairs Program</h5>
					<p>A Brush with Kindness focuses on
					helping households that are
					economically challenged through
					small remodeling projects. Examples
					are: painting, minor home repairs,
					bathroom safety and other
					accessibility issues.</p>
					<h5 className="mb-0">Group Volunteering</h5>
					<p>We encourage groups (churches,
					businesses, youth groups, community
					groups, etc) to work together on our
					projects. Working side-by-side not
					only will benefit the needs of our
					partner families, but it will promote
					team building and camaraderie.</p>
					<div className="spacer"></div>
					<h2>Committees</h2>
					<p>We have several committees that exist
					to support Habitat for Humanity's work.
					We welcome those who would like to
					serve on those committees.</p>				
					<h5 className="mb-0">Construction</h5>
					<p>Advise and help with our builds</p>
					<h5 className="mb-0">Family Selection and Support</h5>
					<p>Help support our partner families in
					obtaining, keeping and maintaining
					their home</p>
					<h5 className="mb-0">Public Relations and Marketing</h5>
					<p>Let the public know about Habitat's
					work and activities</p>
					<h5 className="mb-0">Resource Development</h5>
					<p>Help to raise money to support
					Habitat's work through grants, events,
					donations, and more</p>
					<h5 className="mb-0">ReStore</h5>
					<p>Advise and help with ReStore
					operations</p>
					<h5 className="mb-0">Volunteer Relations</h5>
					<p>Obtain and support our volunteers</p>
					<div className="spacer"></div>
					<h2>RESTORE</h2>
					<p>The Habitat ReStore offers a wide
					variety of volunteer opportunities to
					match your interests and skill level.
					Whether you are a student, working
					professional, senior citizen, or
					anything in between, we have
					rewarding volunteer positions that
					offer flexible schedules to suit your
					lifestyle.</p>
					<h5>General Volunteer Opportunities:</h5>
					<ul>
						<li>Warehouse Volunteers</li>
						<li>Cashiers</li>
						<li>Sales</li>
						<li>Pick-up Volunteers</li>	
					</ul>	
					<div className="spacer"></div>
					<p>We would love to have you join us. For more details and to complete your volunteer information, email <a href="mailto:volunteer@habitatnorthwoods.org">volunteer@habitatnorthwoods.org</a></p>
					<StaticImage src="../../images/every-volunteer-image.png" alt="Every Volunteer Helps" />
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default VolunteerPage

export const Head = () => (
	<Seo
        title="Volunteer | Habitat For Humanity Northwoods Wisconsin"
    />
)